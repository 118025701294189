module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_gatsby@4.25.9_graphql@15.10.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"assets/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"63734d4d33b09dc30c8e6c8f0ff38e19"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-mdx@2.15.0_@mdx-js+mdx@1.6.22_@mdx-js+react@1.6.22_gatsby@4.25.9_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/buildhome/repo/src/layouts/text.tsx"},"extensions":[".mdx"],"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@5.25.0_babel-plugin-styled-components@1.13.3_gatsby@4.25.9_re_kvkqnf7mxeqte4bsvo6amru7q4/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.25.9_babel-eslint@10.1.0_esbuild@0.17.19_react-dom@17.0.2_react@17.0.2_typescript@4.9.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
